<template>
  <div>
    <modal-edit-settings
      v-if="showEditSettings"
      :showModal="showEditSettings"
      :exportgroups="exportgroups"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditSettings"
      :editLabel="'Edit'"
    >
      <template v-slot:title>Settings</template>
    </ui-section-header>

    <table v-if="option" class="table is-fullwidth is-striped">
      <tbody>
        <tr>
          <td width="50%">Category</td>
          <td class="has-text-right">{{ categoryName }}</td>
        </tr>
        <tr>
          <td>Export group</td>
          <td class="has-text-right">
            {{ getExportgroupName(option.Location.ExportgroupId) }}
          </td>
        </tr>
        <tr>
          <td>Required item</td>
          <td class="has-text-right" v-text="textRequiredItem"></td>
        </tr>

        <tr>
          <td>Bookable until hours before reservation</td>
          <td class="has-text-right" v-text="textHoursBeforeReservation"></td>
        </tr>

        <tr>
          <td>Max. amount per reservation</td>
          <td class="has-text-right" v-text="textMaxAmount"></td>
        </tr>

        <tr>
          <td>Min. participants</td>
          <td class="has-text-right" v-text="textMinParticipants"></td>
        </tr>

        <tr>
          <td>Max. participants</td>
          <td class="has-text-right" v-text="textMaxParticipants"></td>
        </tr>

        <tr>
          <td>Min. duration</td>
          <td class="has-text-right" v-text="textMinDuration"></td>
        </tr>

        <tr>
          <td>Max. duration</td>
          <td class="has-text-right" v-text="textMaxDuration"></td>
        </tr>

        <tr>
          <td>Time selectable</td>
          <td class="has-text-right" v-text="textTimeSelectable"></td>
        </tr>
        <tr>
          <td>Avaialable from</td>
          <td class="has-text-right">
            {{ option.Location.AvailableStart | minutesToTime }}
          </td>
        </tr>
        <tr>
          <td>Available until</td>
          <td class="has-text-right">
            {{ option.Location.AvailableEnd | minutesToTime }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locationProvider from '@/providers/location'

const ModalEditSettings = () =>
  import('@/components/Options/ModalEditOptionSettings')

export default {
  components: {
    'modal-edit-settings': ModalEditSettings,
  },

  data() {
    return {
      categories: [],
      exportgroups: [],
      showEditSettings: false,
    }
  },

  computed: {
    ...mapState('optionStore', ['option']),

    textTimeSelectable() {
      if (this.option.Location.TimeSelectable === 1) {
        return 'location only'
      }
      if (this.option.Location.TimeSelectable === 2) {
        return 'location & booker'
      }
      return 'Not applicable'
    },

    textRequiredItem() {
      if (this.option.Location.RequiredItem === 1) {
        return 'Optional'
      }
      if (this.option.Location.RequiredItem === 2) {
        return 'Required'
      }
      return 'Not applicable'
    },

    textMaxAmount() {
      return this.option.Location.MaxAmount
        ? this.option.Location.MaxAmount
        : 'Not applicable'
    },

    textMinParticipants() {
      return this.option.Location.MinimumParticipants > 0
        ? this.option.Location.MinimumParticipants
        : 'Not applicable'
    },

    textMaxParticipants() {
      return this.option.Location.MaximumParticipants > 0
        ? this.option.Location.MaximumParticipants
        : 'Not applicable'
    },

    textMinDuration() {
      return this.option.Location.MinimumReservationDuration > 0
        ? this.option.Location.MinimumReservationDuration / 60
        : 'Not applicable'
    },

    textMaxDuration() {
      return this.option.Location.MaximumReservationDuration > 0
        ? this.option.Location.MaximumReservationDuration / 60
        : 'Not applicable'
    },

    textHoursBeforeReservation() {
      return this.option.Location.HoursBeforeReservation
        ? this.option.Location.HoursBeforeReservation < 24
          ? this.option.Location.HoursBeforeReservation + 'hrs'
          : this.option.Location.HoursBeforeReservation / 24 + 'days'
        : 'Not applicable'
    },

    categoryName() {
      let name = ''

      if (this.option) {
        let categoryIndex = this.categories.findIndex(
          (c) => c.Type === this.option.Location.CategoryId
        )
        if (categoryIndex > -1) {
          name = this.categories[categoryIndex].Name
        }
      }

      return name
    },
  },

  created() {
    this.getLocationExportgroups()

    this.categories.push({ Id: 1, Type: 'FB', Name: 'Food & beverage' })
    this.categories.push({ Id: 2, Type: 'AV', Name: 'Audio visual' })
    this.categories.push({ Id: 3, Type: 'Parking', Name: 'Parking' })
    this.categories.push({ Id: 4, Type: 'Other', Name: 'Other' })
  },

  methods: {
    getLocationExportgroups() {
      locationProvider.methods
        .getLocationExportgroups(this.option.Location.LocationId)
        .then((response) => {
          if (response.status === 200) {
            this.exportgroups = response.data
          }
        })
    },

    getExportgroupName(exportgroupId) {
      let name = ''
      let exportgroupIndex = this.exportgroups.findIndex(
        (e) => e.Id === exportgroupId
      )

      if (exportgroupIndex > -1) {
        name = this.exportgroups[exportgroupIndex].Name
      }
      return name
    },

    setShowEditSettings() {
      this.showEditSettings = true
    },

    hideModal() {
      this.showEditSettings = false
    },
  },
}
</script>
